import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StandardForm } from '@vp/swan';
import { getSdpUrl } from '@99designs/design-services-common';
import { MultiStep } from '@99designs/swan-wizard';
import { usePage } from '@99designs/tracking';
import { BriefFormProvider } from '../BriefContext/BriefFormContext';
import { BriefFormProps, FormInput } from './BriefForm';
import { FieldGroup, fieldGroups, getFormGroups } from './FieldGroup';
import { FieldProcessingProvider } from './FieldProcessingProvider';
import { Review } from './Review';
import { SubmitBriefButton } from './SubmitBriefButton';
import { ViewContextProvider } from './ViewContext';
import { getSubmit } from './getSubmit';
import { useRegisterWithTracking } from './useRegisterWithTracking';

function useIsStepValid(
    groups: ReturnType<typeof fieldGroups>,
    formErrors: { [key: string]: unknown },
    getValues: (id: string) => string,
    trigger: (name?: string | string[]) => Promise<boolean>
) {
    return useCallback(
        async (currentStep: number) => {
            // We subtract 1 from the current step because the intro step is not a form group

            const group = groups[currentStep - 1];
            if (!group) {
                return true;
            }

            return await trigger(group.fields.map((f) => f.id));
        },
        [groups, trigger, formErrors, getValues]
    );
}

export const ReviewForm: React.FC<BriefFormProps> = ({
    brief,
    serverValidationErrors,
    loading,
    locale,
    onSubmit,
}) => {
    const { intro, groups, workEntityField } = getFormGroups(brief);

    const { pageData } = usePage();

    const methods = useForm<FormInput>();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        clearErrors,
        setError,
        formState: { errors: clientErrors, dirtyFields },
        getValues,
        trigger,
    } = methods;

    const formErrors =
        Object.keys(serverValidationErrors).length > 0 ? serverValidationErrors : clientErrors;

    const registerWithTracking = useRegisterWithTracking(
        brief,
        register,
        getValues,
        dirtyFields,
        pageData
    );

    const steps = [
        <ViewContextProvider value={{ isReviewMode: true, isMultiStep: true }}>
            <Review
                groups={[intro, ...groups]}
                briefId={brief.id}
                groupFormSections={[intro, ...groups].reduce((acc, g) => {
                    acc[g.id] = (
                        <FieldGroup
                            key={g.id}
                            group={g}
                            register={registerWithTracking}
                            errors={formErrors}
                            setValue={setValue}
                            watch={watch}
                            clearErrors={clearErrors}
                            setError={setError}
                        />
                    );
                    return acc;
                }, {} as Record<string, React.ReactNode>)}
                product={brief.product}
                workEntity={workEntityField}
                submitButton={() => (
                    <SubmitBriefButton
                        briefId={brief.id}
                        fulfilmentStrategy={brief.fulfilmentStrategy}
                        locale={locale}
                        loading={loading}
                    />
                )}
            />
        </ViewContextProvider>,
    ];

    const validate = useIsStepValid(groups, formErrors, getValues, trigger);

    if (brief.groups === undefined || !brief.product?.key) {
        return null;
    }

    return (
        <StandardForm
            style={{ height: '100%' }}
            onSubmit={getSubmit(handleSubmit, onSubmit, brief.id)}
        >
            <FieldProcessingProvider>
                <BriefFormProvider briefId={brief.id}>
                    <FormProvider {...methods}>
                        <MultiStep
                            steps={steps}
                            validate={validate}
                            title={brief.product.title}
                            exitUrl={
                                brief.product.fulfilmentMpvId
                                    ? getSdpUrl(brief.product.fulfilmentMpvId)
                                    : '/design'
                            }
                        />
                    </FormProvider>
                </BriefFormProvider>
            </FieldProcessingProvider>
        </StandardForm>
    );
};
