/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';
import {
    Field_BudgetField_Fragment,
    Field_ClientConfiguredField_Fragment,
    Field_ContestUpgradesChoiceField_Fragment,
    Field_DateField_Fragment,
    Field_FilesField_Fragment,
    Field_GridChoiceField_Fragment,
    Field_InputField_Fragment,
    Field_LogoContestTierChoiceField_Fragment,
    Field_MultiChoiceField_Fragment,
    Field_MultiGridChoiceField_Fragment,
    Field_MultiInputField_Fragment,
    Field_MultiSelectChoiceField_Fragment,
    Field_PrintServiceOptionsField_Fragment,
    Field_RadioChoiceField_Fragment,
    Field_SelectChoiceField_Fragment,
    Field_SquarespacePackageChoiceField_Fragment,
    Field_TextAreaField_Fragment,
    Field_WorkEntityField_Fragment,
    ValidationErrorFragment,
} from '../../../brief.generated';

export type DesignLiveAvailableDatesQueryVariables = Types.Exact<{
    locale: Types.Scalars['String'];
    timezone: Types.Scalars['String'];
}>;

export type DesignLiveAvailableDatesQuery = {
    __typename: 'Query';
    setField: {
        __typename: 'Dates';
        dates: Array<{ __typename: 'AppointmentDate'; date: any; isAvailable: boolean }>;
    };
};

export type DesignLiveAvailableTimesQueryVariables = Types.Exact<{
    date: Types.Scalars['Time'];
    locale: Types.Scalars['String'];
    timezone: Types.Scalars['String'];
}>;

export type DesignLiveAvailableTimesQuery = {
    __typename: 'Query';
    setField: {
        __typename: 'Times';
        times: Array<{ __typename: 'Timeslot'; startTimeUTC: any; endTimeUTC: any }>;
    };
};

export type BriefSetDateMutationVariables = Types.Exact<{
    briefId: Types.Scalars['ID'];
    key: Types.Scalars['String'];
    value: Types.Scalars['String'];
}>;

export type BriefSetDateMutation = {
    __typename: 'Mutation';
    setField: {
        __typename: 'StructuredBrief';
        id: string;
        dynamicFields: Array<
            | ({ __typename: 'SelectChoiceField' } & Field_SelectChoiceField_Fragment)
            | ({ __typename: 'MultiSelectChoiceField' } & Field_MultiSelectChoiceField_Fragment)
            | ({ __typename: 'GridChoiceField' } & Field_GridChoiceField_Fragment)
            | ({ __typename: 'RadioChoiceField' } & Field_RadioChoiceField_Fragment)
            | ({ __typename: 'MultiChoiceField' } & Field_MultiChoiceField_Fragment)
            | ({ __typename: 'BudgetField' } & Field_BudgetField_Fragment)
            | ({ __typename: 'DateField' } & Field_DateField_Fragment)
            | ({ __typename: 'FilesField' } & Field_FilesField_Fragment)
            | ({ __typename: 'TextAreaField' } & Field_TextAreaField_Fragment)
            | ({
                  __typename: 'SquarespacePackageChoiceField';
              } & Field_SquarespacePackageChoiceField_Fragment)
            | ({
                  __typename: 'LogoContestTierChoiceField';
              } & Field_LogoContestTierChoiceField_Fragment)
            | ({
                  __typename: 'ContestUpgradesChoiceField';
              } & Field_ContestUpgradesChoiceField_Fragment)
            | ({ __typename: 'PrintServiceOptionsField' } & Field_PrintServiceOptionsField_Fragment)
            | ({ __typename: 'InputField' } & Field_InputField_Fragment)
            | ({ __typename: 'MultiInputField' } & Field_MultiInputField_Fragment)
            | ({ __typename: 'MultiGridChoiceField' } & Field_MultiGridChoiceField_Fragment)
            | ({ __typename: 'ClientConfiguredField' } & Field_ClientConfiguredField_Fragment)
            | ({ __typename: 'WorkEntityField' } & Field_WorkEntityField_Fragment)
        >;
        errors: Array<{ __typename: 'StructuredBriefValidationError' } & ValidationErrorFragment>;
    };
};

export const DesignLiveAvailableDatesDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'DesignLiveAvailableDates' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'setField' },
                        name: { kind: 'Name', value: 'designLiveAvailableDates' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'timezone' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'timezone' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dates' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isAvailable' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useDesignLiveAvailableDatesQuery__
 *
 * To run a query within a React component, call `useDesignLiveAvailableDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignLiveAvailableDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesignLiveAvailableDatesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDesignLiveAvailableDatesQuery(
    baseOptions: Apollo.QueryHookOptions<
        DesignLiveAvailableDatesQuery,
        DesignLiveAvailableDatesQueryVariables
    >
) {
    return Apollo.useQuery<DesignLiveAvailableDatesQuery, DesignLiveAvailableDatesQueryVariables>(
        DesignLiveAvailableDatesDocument,
        baseOptions
    );
}
export function useDesignLiveAvailableDatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DesignLiveAvailableDatesQuery,
        DesignLiveAvailableDatesQueryVariables
    >
) {
    return Apollo.useLazyQuery<
        DesignLiveAvailableDatesQuery,
        DesignLiveAvailableDatesQueryVariables
    >(DesignLiveAvailableDatesDocument, baseOptions);
}
export type DesignLiveAvailableDatesQueryHookResult = ReturnType<
    typeof useDesignLiveAvailableDatesQuery
>;
export type DesignLiveAvailableDatesLazyQueryHookResult = ReturnType<
    typeof useDesignLiveAvailableDatesLazyQuery
>;
export type DesignLiveAvailableDatesQueryResult = Apollo.QueryResult<
    DesignLiveAvailableDatesQuery,
    DesignLiveAvailableDatesQueryVariables
>;
export const DesignLiveAvailableTimesDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'DesignLiveAvailableTimes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Time' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'setField' },
                        name: { kind: 'Name', value: 'designLiveAvailableTimes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'timezone' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'timezone' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'times' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'startTimeUTC' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'endTimeUTC' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useDesignLiveAvailableTimesQuery__
 *
 * To run a query within a React component, call `useDesignLiveAvailableTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignLiveAvailableTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesignLiveAvailableTimesQuery({
 *   variables: {
 *      date: // value for 'date'
 *      locale: // value for 'locale'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDesignLiveAvailableTimesQuery(
    baseOptions: Apollo.QueryHookOptions<
        DesignLiveAvailableTimesQuery,
        DesignLiveAvailableTimesQueryVariables
    >
) {
    return Apollo.useQuery<DesignLiveAvailableTimesQuery, DesignLiveAvailableTimesQueryVariables>(
        DesignLiveAvailableTimesDocument,
        baseOptions
    );
}
export function useDesignLiveAvailableTimesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DesignLiveAvailableTimesQuery,
        DesignLiveAvailableTimesQueryVariables
    >
) {
    return Apollo.useLazyQuery<
        DesignLiveAvailableTimesQuery,
        DesignLiveAvailableTimesQueryVariables
    >(DesignLiveAvailableTimesDocument, baseOptions);
}
export type DesignLiveAvailableTimesQueryHookResult = ReturnType<
    typeof useDesignLiveAvailableTimesQuery
>;
export type DesignLiveAvailableTimesLazyQueryHookResult = ReturnType<
    typeof useDesignLiveAvailableTimesLazyQuery
>;
export type DesignLiveAvailableTimesQueryResult = Apollo.QueryResult<
    DesignLiveAvailableTimesQuery,
    DesignLiveAvailableTimesQueryVariables
>;
export const BriefSetDateDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'briefSetDate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'briefId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'setField' },
                        name: { kind: 'Name', value: 'briefSetClientConfigured' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'briefId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'briefId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'key' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'value' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dynamicFields' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'Field' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'errors' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ValidationError' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Field' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DynamicField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiInputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextAreaField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SelectChoiceField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RadioChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DateField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FilesField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiGridChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ClientConfiguredField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WorkEntityField' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'InputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'helperText' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'inputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiInputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiInputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiInputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'multiInputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextAreaField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextAreaField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TextAreaField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'textAreaValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValues' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GridChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RadioChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RadioChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RadioChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'view' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BudgetField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'BudgetField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DateField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DateField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'DateField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FilesField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilesField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FilesField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allowedFileTypes' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filesValue' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bucket' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewable' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'downloadUrl' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewUrl' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiGridChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiGridChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiGridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiGridChoiceValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxSelections' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'validationPrompt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'reviewImage' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ClientConfiguredField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ClientConfiguredField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ClientConfiguredField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clientConfiguredValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WorkEntityField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkEntityField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'WorkEntityField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'workEntityValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ValidationError' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'StructuredBriefValidationError' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                ],
            },
        },
    ],
};
export type BriefSetDateMutationFn = Apollo.MutationFunction<
    BriefSetDateMutation,
    BriefSetDateMutationVariables
>;

/**
 * __useBriefSetDateMutation__
 *
 * To run a mutation, you first call `useBriefSetDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBriefSetDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [briefSetDateMutation, { data, loading, error }] = useBriefSetDateMutation({
 *   variables: {
 *      briefId: // value for 'briefId'
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useBriefSetDateMutation(
    baseOptions?: Apollo.MutationHookOptions<BriefSetDateMutation, BriefSetDateMutationVariables>
) {
    return Apollo.useMutation<BriefSetDateMutation, BriefSetDateMutationVariables>(
        BriefSetDateDocument,
        baseOptions
    );
}
export type BriefSetDateMutationHookResult = ReturnType<typeof useBriefSetDateMutation>;
export type BriefSetDateMutationResult = Apollo.MutationResult<BriefSetDateMutation>;
export type BriefSetDateMutationOptions = Apollo.BaseMutationOptions<
    BriefSetDateMutation,
    BriefSetDateMutationVariables
>;
