import { FieldErrors, useForm } from 'react-hook-form';
import { Link, P, StandardForm, Typography } from '@vp/swan';
import { FormattedReactMessage, asNinetyNineAbsUrl } from '@99designs/design-services-common';
import { Maybe, StructuredBriefFieldsInput } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { sendNavigationEvent, usePage } from '@99designs/tracking';
import { BriefFormProvider } from '../BriefContext/BriefFormContext';
import { FieldSet } from './Field';
import { FieldGroup, Legend, fieldGroups } from './FieldGroup';
import { FieldProcessingProvider } from './FieldProcessingProvider';
import { SubmitBriefButton } from './SubmitBriefButton';
import {
    BriefProductFragment,
    StructuredBriefFragment,
    ValidationErrorFragment,
} from './brief.generated';
import { getSubmit } from './getSubmit';
import { useRegisterWithTracking } from './useRegisterWithTracking';

export interface BriefFormProps {
    brief: {
        product: Maybe<BriefProductFragment>;
    } & StructuredBriefFragment;
    onSubmit: (data: StructuredBriefFieldsInput, briefId: string) => void;
    serverValidationErrors: FieldErrors;
    loading: boolean;
    locale: string;
}

// TODO: dynamically define form input
export type FormInput = any;

const dtaClickEventTracking = {
    label: 'Design Transfer Agreement Viewed',
    eventDetail: '/design/briefs/create;/c;BriefForm;DesignTransferAgreement-CTA',
    navigationDetail: 'DesignTransferAgreement-CTA',
    category: 'Navigation Clicked',
};

// Warning: This form currently only supports a subset of dynamic schema questions. You may need to implement new question types.
export function BriefForm({
    onSubmit,
    brief,
    serverValidationErrors,
    loading,
    locale,
}: BriefFormProps) {
    const { pageData } = usePage();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        clearErrors,
        setError,
        formState: { errors: clientErrors, dirtyFields },
        getValues,
    } = useForm<FormInput>();

    const registerWithTracking = useRegisterWithTracking(
        brief,
        register,
        getValues,
        dirtyFields,
        pageData
    );

    if (brief.groups === undefined) {
        return null;
    }

    const withRequestTypeField = brief.dynamicFields.some((f) => f.id === 'requestType');
    const groups = fieldGroups(brief);
    const formErrors =
        Object.keys(serverValidationErrors).length > 0 ? serverValidationErrors : clientErrors;
    return (
        <BriefFormProvider briefId={brief.id}>
            <FieldProcessingProvider>
                <StandardForm onSubmit={getSubmit(handleSubmit, onSubmit, brief.id)}>
                    {groups.map((g) => {
                        return (
                            <FieldGroup
                                key={g.id}
                                group={g}
                                register={registerWithTracking}
                                errors={formErrors}
                                setValue={setValue}
                                watch={watch}
                                clearErrors={clearErrors}
                                setError={setError}
                                titleFontSkin={'title-section'}
                            />
                        );
                    })}
                    {brief.fulfilmentStrategy === 'FULFILMENT_STRATEGY_COMMUNITY' && (
                        <P mt={'7'}>
                            <FormattedReactMessage
                                message={__(
                                    'By adding to cart and completing this purchase, you agree to the terms of the <Link>design transfer agreement</Link>.'
                                )}
                                replacements={{
                                    Link: (
                                        <Link
                                            onClick={() =>
                                                sendNavigationEvent({
                                                    ...dtaClickEventTracking,
                                                    ...pageData,
                                                })
                                            }
                                            target={'_blank'}
                                            href={asNinetyNineAbsUrl(
                                                '/legal/design-transfer-agreement'
                                            )}
                                        />
                                    ),
                                }}
                            />
                        </P>
                    )}
                    {
                        // In create we introduced 2 schemas, 1 with and another without the DesignLive question based on the locale
                        // If the question exists don't show this, if not show it
                        // TODO: Move this logic to create and introduce a new schema field instead of a new question
                        !withRequestTypeField &&
                            brief.fulfilmentStrategy === 'FULFILMENT_STRATEGY_CARE' && (
                                <FieldSet>
                                    <Legend>{__(`Reviewing your design`)}</Legend>
                                    <Typography fontSize="standard" fontWeight="bold">
                                        {__(`Review over email`)}
                                    </Typography>
                                    <Typography mb={'5'}>
                                        {__(
                                            `Provide written feedback over 3 rounds of revisions. Receive your design within 24 hours.`
                                        )}
                                    </Typography>
                                </FieldSet>
                            )
                    }
                    <SubmitBriefButton
                        briefId={brief.id}
                        fulfilmentStrategy={brief.fulfilmentStrategy}
                        locale={locale}
                        loading={loading}
                    />
                </StandardForm>
            </FieldProcessingProvider>
        </BriefFormProvider>
    );
}

export function castServerErrorsToClientErrors(
    serverErrors: ValidationErrorFragment[] | undefined
): FieldErrors {
    if (!serverErrors) return {};
    return serverErrors.reduce(
        (acc, { field, reason }: ValidationErrorFragment) => ({
            ...acc,
            [field]: { message: reason },
        }),
        {}
    );
}
