import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { FullPageSpinner } from '@99designs/briefs';
import { getCurrentCulture } from '@99designs/design-services-common';
import {
    bugtracker,
    getApolloErrorType,
    useIdentityContext,
} from '@99designs/design-services-common';
import {
    ReviewForm,
    castServerErrorsToClientErrors,
    useBriefContext,
    useBriefQuery,
    useUpdateBriefMutation,
} from '@99designs/feature-design-services-brief';
import { StructuredBriefFieldsInput } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useSendPageViewOnce } from '@99designs/tracking';
import { getBriefBaseUrl } from '../lib/getBriefBaseUrl';

export const Review: React.FC = () => {
    const { jobId } = useParams();
    const briefId = jobId as string;
    const locale = useMemo(() => getCurrentCulture(), []);

    useSendPageViewOnce(locale);
    const { SignIn, isSignedIn } = useIdentityContext();
    const { updateProduct } = useBriefContext();

    const { data, loading, error } = useBriefQuery({
        variables: {
            id: briefId,
        },
        onCompleted: (data) => {
            if (data && data.brief.product && data.brief.fulfilment) {
                updateProduct({
                    title: data.brief.product.title,
                    details: data.brief.product.details,
                    pricing: data.brief.product.pricing,
                    key: data.brief.fulfilment.productKey,
                    fulfilmentMpvId: data.brief.fulfilment.mpvId,
                });
            }
        },
    });

    const [updateBrief, { data: updateData, loading: updateLoading }] = useUpdateBriefMutation({
        onCompleted: (data) => {
            const formHasValidationErrors = data?.brief.errors && data.brief.errors.length > 0;
            if (formHasValidationErrors) {
                return;
            }
            window.location.assign(`${getBriefBaseUrl()}/${briefId}/submit`);
        },
    });

    function onSubmit(fields: StructuredBriefFieldsInput, briefId: string) {
        updateBrief({ variables: { id: briefId, fields: { ...fields } } });
    }

    if (error) {
        const errorType = getApolloErrorType(error);
        if (errorType === 'UNAUTHENTICATED') {
            if (!isSignedIn) {
                SignIn();
                return <FullPageSpinner />;
            }

            bugtracker.notify(
                new Error(
                    `User is logged in on Vistaprint but has no 99designs session: ${error.message}`
                ),
                (event) => {
                    event.addMetadata('debug info', 'briefId', briefId);
                }
            );

            return (
                <div>{`User is logged in on Vistaprint but has no 99designs session: ${error.message}`}</div>
            );
        }

        if (errorType === 'UNKNOWN_ERROR') {
            bugtracker.notify(
                new Error(`Unknown error loading brief: ${error.message}`),
                (event) => {
                    event.addMetadata('debug info', 'briefId', briefId);
                }
            );
        }

        return <div>{error.message}</div>;
    }

    if (loading || !data) {
        return <FullPageSpinner />;
    }

    if (!data.brief.product) {
        bugtracker.notify(new Error('Brief product is missing from request'), (event) => {
            event.addMetadata('debug info', 'briefId', briefId);
        });

        return <div>Brief product is missing from request</div>;
    }

    return (
        <>
            <Helmet>
                <title>{__('Design Services Brief Review')} | Vistaprint</title>
            </Helmet>
            <ReviewForm
                locale={locale}
                loading={updateLoading}
                onSubmit={onSubmit}
                brief={data.brief}
                serverValidationErrors={castServerErrorsToClientErrors(updateData?.brief?.errors)}
            />
        </>
    );
};
