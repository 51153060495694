import i18next from 'i18next';
import { Currency, toUnit } from './currency';

// This price function expects the amount to be in cents it also doesn't allow
// for decimal places. Mostly used for legacy catalog pricing.
export function PriceFromCents({
    amount,
    currency,
    decimalPlaces,
}: {
    amount: number;
    currency: Currency;
    decimalPlaces?: number;
}) {
    return (
        <>
            {i18next.t(`{{val, currency(${currency})}}`, {
                val: toUnit(amount, currency),
                formatParams: {
                    val: {
                        maximumFractionDigits: decimalPlaces || 0,
                    },
                },
            })}
        </>
    );
}

// This price function expects the amount to be in float, as returned by website
// pricing, it allows for decimal places
export function PriceFromFloat({ amount, currency }: { amount: number; currency: Currency }) {
    return (
        <>
            {i18next.t(`{{val, currency(${currency})}}`, {
                val: amount,
                formatParams: {
                    val: {
                        maximumFractionDigits: 2,
                    },
                },
            })}
        </>
    );
}
