import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Button,
    Icon,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogNav,
    Spinner,
} from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useUpdateBriefMutation } from './brief.generated';
import { transformBriefFormData } from './getSubmit';

function useReviewSubmit(briefId: string) {
    const form = useFormContext();
    const setError = form?.setError;
    const [updateBrief, data] = useUpdateBriefMutation({
        update: (cache, { data }) => {
            if (!data) {
                return;
            }
            if (data.brief.errors) {
                data.brief.errors.forEach((error) => {
                    setError(error.field, { type: 'server', message: error.reason });
                });
            }
            if (data.brief.dynamicFields) {
                cache.modify({
                    id: cache.identify(data.brief),
                    fields: {
                        dynamicFields: () => data.brief.dynamicFields,
                    },
                });
            }
        },
    });

    const onSubmit = useCallback(() => {
        const fields = form.getValues();

        return updateBrief({
            variables: { id: briefId, fields: { ...transformBriefFormData(fields) } },
        });
    }, [form, updateBrief, briefId]);

    return { onSubmit, data };
}

function useCancel() {
    const form = useFormContext();
    const [currentValue, setCurrentValue] = useState(form.getValues());

    const onCancel = useCallback(() => {
        form.reset(currentValue);
    }, [form, currentValue]);

    const onOpen = useCallback(() => {
        setCurrentValue(form.getValues());
    }, [form]);

    return { onCancel, onOpen };
}

export function useEditState(briefId: string) {
    const [isOpen, setIsOpen] = useState(false);
    const {
        onSubmit,
        data: { loading },
    } = useReviewSubmit(briefId);
    const { onCancel, onOpen } = useCancel();

    const open = () => {
        onOpen();
        setIsOpen(true);
    };

    const cancel = () => {
        onCancel();
        setIsOpen(false);
    };

    const save = async () => {
        await onSubmit();
        setIsOpen(false);
    };

    return { isOpen, isSaving: loading, open, cancel, save };
}

export function EditFormGroupModal({
    briefId,
    children,
}: {
    briefId: string;
    children: React.ReactNode;
}) {
    const { isOpen, isSaving, open, cancel, save } = useEditState(briefId);
    return (
        <>
            <ModalDialog isOpen={isOpen} onRequestDismiss={cancel}>
                <ModalDialogContent aria-labelledby="modal dialog general">
                    <ModalDialogNav>
                        <ModalDialogCloseButton accessibleText="Close" />
                    </ModalDialogNav>
                    <ModalDialogBody>{children}</ModalDialogBody>
                    <ModalDialogFooter pinned>
                        <ModalDialogButtons>
                            <Button skin="secondary" onClick={cancel}>
                                {__('Cancel')}
                            </Button>
                            <Button skin="primary" onClick={save}>
                                {isSaving && <Spinner accessibleText={__('Loading...')} />}{' '}
                                {__('Save')}
                            </Button>
                        </ModalDialogButtons>
                    </ModalDialogFooter>
                </ModalDialogContent>
            </ModalDialog>
            <Button
                style={{
                    alignSelf: 'flex-end',
                }}
                skin={'link'}
                onClick={open}
            >
                <Icon iconType="edit" />
            </Button>
        </>
    );
}
