import React from 'react';

function NumberIcon({ num }: { num: number }) {
    return (
        <svg viewBox="0 0 100 100" width="16" height="16">
            <circle cx="50" cy="50" r="45" fill="#2F3A53" />
            <text
                x="50"
                y="55"
                fontFamily="Graphik"
                fontSize="80"
                fill="white"
                textAnchor="middle"
                alignmentBaseline="middle"
                fontWeight={'bold'}
            >
                {num}
            </text>
        </svg>
    );
}

export default NumberIcon;
