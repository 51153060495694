import { __url } from '@99designs/i18n';
import { DS_BRIEF_BASE_URL, DS_BRIEF_CREATE_URL, DS_BRIEF_MULTI_STEP_URL } from '../../../consts';

export function getBriefBaseUrl() {
    if (process.env.NODE_ENV === 'development') {
        return DS_BRIEF_BASE_URL;
    }
    return __url(`${DS_BRIEF_BASE_URL}`);
}

export function getBriefMultiStepUrl() {
    if (process.env.NODE_ENV === 'development') {
        return `http://localhost:3002/${DS_BRIEF_MULTI_STEP_URL}`;
    }
    return __url(`${DS_BRIEF_MULTI_STEP_URL}`);
}

export function getBriefCreateUrl() {
    if (process.env.NODE_ENV === 'development') {
        return DS_BRIEF_CREATE_URL;
    }
    return __url(`${DS_BRIEF_CREATE_URL}`);
}
