import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, Column, GridContainer, H1, Row, Typography } from '@vp/swan';
import { Maybe } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { StepLayout } from '@99designs/swan-wizard';
import { EditFormGroupModal } from './EditFormGroupModal';
import { FieldReview } from './Field';
import { FieldGroupData } from './FieldGroup';
import { Information } from './Information';
import { StepProductCard } from './StepProductCard';
import { BriefProductFragment, Field_WorkEntityField_Fragment } from './brief.generated';

export function Review({
    groups,
    product,
    workEntity,
    submitButton,
    groupFormSections,
    briefId,
}: {
    groups: FieldGroupData[];
    workEntity?: {
        title: string;
        description: string | null;
        field: Field_WorkEntityField_Fragment;
    };
    product: Maybe<BriefProductFragment>;
    submitButton: React.ComponentType;
    groupFormSections: Record<string, React.ReactNode>;
    briefId: string;
}) {
    const nav = useNavigate();
    return (
        <Box style={{ width: '100%' }} mb={8} mx={5} mt={5}>
            <StepLayout
                key={'review'}
                NextAction={submitButton}
                PreviousAction={() => (
                    <Button
                        onClick={() => {
                            const currentPath = window.location.pathname;
                            const newPath = currentPath.replace(/\/review$/, '');
                            const url = new URL(newPath, window.location.origin);
                            url.searchParams.set('step', (groups.length - 1).toString());
                            nav(url.pathname + url.search);
                        }}
                    >
                        {__('Back')}
                    </Button>
                )}
                Information={() => <Information product={product} />}
                showProgressBar={false}
                maxWidth={'894px'}
            >
                <H1>{__('Give your brief a once-over')}</H1>
                <Typography mb="6">
                    {__('Take a moment to review your design project details')}
                </Typography>
                {product && <StepProductCard workEntity={workEntity} product={product} />}
                {groups.map((group) => {
                    return (
                        <Card my="6" p="5" bordered>
                            <GridContainer>
                                <Row mb="5">
                                    <Column span={11} spanXs={11}>
                                        <Typography fontSkin="title-subsection">
                                            {group.title}
                                        </Typography>
                                    </Column>
                                    <Column
                                        span={1}
                                        spanXs={1}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        <EditFormGroupModal briefId={briefId}>
                                            {groupFormSections[group.id]}
                                        </EditFormGroupModal>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column span={12}>
                                        {group.fields.map((field) => {
                                            return (
                                                <FieldReview
                                                    skin="small"
                                                    key={field.id}
                                                    field={field}
                                                    colWidthOverride={6}
                                                    showTitle={group.fields.length > 1}
                                                />
                                            );
                                        })}
                                    </Column>
                                </Row>
                            </GridContainer>
                        </Card>
                    );
                })}
            </StepLayout>
        </Box>
    );
}
