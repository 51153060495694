import { Box, Card, FlexBox, Li, Typography, Ul, tokensRaw } from '@vp/swan';
import { Currency, Price } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { useBriefContext } from '../BriefContext';
import { WorkEntityField } from './FormFields';
import { BriefProductFragment, Field_WorkEntityField_Fragment } from './brief.generated';

export interface Product {
    title: BriefProductFragment['title'];
    details: Array<{
        __typename: 'ProductPackageDetails';
        title: string;
        description?: string;
    }>;
    additionalDetails?: Array<{
        __typename: 'ProductPackageDetails';
        title: string;
        description: string;
    }>;
    pricing: BriefProductFragment['pricing'];
    fulfilmentMpvId?: BriefProductFragment['fulfilmentMpvId'];
    description?: string;
}

export type WorkEntityDisplay = {
    title: string;
    description: string | null;
    field: Field_WorkEntityField_Fragment;
};

export interface StepProductCardProps {
    product: Product;
    workEntity?: WorkEntityDisplay;
}

export function StepProductCard({ product, workEntity }: StepProductCardProps) {
    const { product: updatedProduct } = useBriefContext();
    const p = updatedProduct || product;
    return (
        <Card
            bordered
            p="5"
            style={{
                minHeight: '96px',
                borderRadius: `${tokensRaw.SwanSemBorderRadiusStandard}`,
            }}
        >
            <FlexBox alignItems="center" justifyContent="space-between" flexWrap="wrap-reverse">
                <Box style={{ width: '100%' }}>
                    <FlexBox flexDirection="row" justifyContent="space-between">
                        <Typography fontSize="large" fontWeight="bold" textAlign="left" pr={8}>
                            {p.title}
                        </Typography>
                        <StepProductPrice
                            amountInCents={p.pricing.amount.amountInCents}
                            currency={p.pricing.amount.currency}
                        />
                    </FlexBox>
                    <Ul mt={'4'}>
                        {p.details.map((item) => (
                            <Li key={item.title}>{item.title}</Li>
                        ))}
                    </Ul>
                    {workEntity && (
                        <Box mt={4}>
                            <WorkEntityField
                                workEntityValue={workEntity.field.workEntityValue}
                                title={workEntity.title}
                                description={workEntity.description}
                            />
                        </Box>
                    )}
                </Box>
            </FlexBox>
        </Card>
    );
}

function StepProductPrice({
    amountInCents,
    currency,
}: {
    amountInCents: number;
    currency: string;
}) {
    return (
        <Typography fontSize="large" fontWeight="bold" mb={0}>
            <Price amount={amountInCents} currency={currency as Currency} />
        </Typography>
    );
}
