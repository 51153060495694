import { useUpdateField } from '../../useUpdateField';
import { useBriefSetProductOptionsDropdownMutation } from './product-options.generated';

export function useUpdateProductOptionsDropdown(
    key: string,
    briefId?: string,
    initialValue?: string
) {
    const [setProductOptionsDropdown] = useBriefSetProductOptionsDropdownMutation();
    return useUpdateField({ key, setField: setProductOptionsDropdown, briefId, initialValue });
}

export default useUpdateProductOptionsDropdown;
